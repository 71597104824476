import axios from 'axios';
//接口地址引入
import interfaces from "../utils/skillsBaseUrl";
// 后端接口请求地址
const baseUrl = interfaces.baseUrl;
//创建axios
const instance = axios.create({
    baseURL: baseUrl,
    timeout: 60000
})
//clientId  clientKey
const clientIdKey={
    "clientId":"lenovo_zui",
    "clientKey":"lenovo_zui"
}
// 获取token的一个开关，防止重复请求
let getTokenStatus = true;
// 获取token的一个开关，防止重复请求
let getTokenStatus2 = true;
// 刷新token的一个开关，防止重复请求
let isRefreshing = true;
// 重试队列，每一项将是一个待执行的函数形式 
let subscribers = [];

// 执行队列
function onAccessTokenFetched() {
    subscribers.forEach((callback) => {
        console.log("在执行了啊")
        callback();
    })
    subscribers = [];
}

// 添加队列
function addSubscriber(callback) {
    subscribers.push(callback)
}


// 在instance上添加请求拦截器 补充请求头token信息
instance.interceptors.request.use(function (config) {
    console.log(config)
    // 从vuex中取出token
    const token = localStorage.getItem("access-token");
    // console.log(token)
    // 如果有token则 添加到headers中
    if (token) {
        config.headers["access-token"] = token;
    }else{
        // console.log(getTokenStatus2)
        if (getTokenStatus2) {
            getTokenStatus2 = false;
            return getToken(clientIdKey).then((res) => {
                const data = res.data;
                // console.log(data.result["access_token"])
                if (data.code == 0) {
                    // 定义赋值新的token
                    // const newToken = data.data
                    localStorage.setItem("access-token", data.result["access_token"]);
                    localStorage.setItem("token-type", data.result["token_type"]);
                    localStorage.setItem("refresh-token", data.result["refresh_token"]);
                    // 已经刷新了token，将所有队列中的请求进行重试
                    onAccessTokenFetched();
                    // 再发请求 
                    return instance(config);
                } else {
                    console.log('请求getToken接口失败')
                    getTokenStatus2 = true
                    return false;
                }
            }).catch(() => {
                console.log('请求getToken接口异常')
                // getTokenStatus2 = true
                return false;
            }).finally(() => {
                getTokenStatus2 = true
                return false;
            })
        } else {
            console.log(config)
             // 正在刷新token 将失败401的请求放入队列
            const retryOriginalRequest = new Promise((resolve) => {
                console.log(config)
                addSubscriber(() => {
                    resolve(instance(config))
                })
            });
            return retryOriginalRequest;
        }
    }
    return config
}, function (error) {
    console.log(1112334444)
    return Promise.reject(error)
})
// 响应拦截
instance.interceptors.response.use(function (response) {
    console.log(response)
    var code = response.data.code;
    if (code == 0) {  // 成功
        return response;
    }else { // 其他
        console.log("请求失败", response);
    }
    return response;
}, error => {
    console.log( error);
    if(error.response&&error.response.status==401){
        if (isRefreshing) {
            isRefreshing = false;
            return getToken(clientIdKey).then((res) => {
                const data = res.data;
                if (data.code == 0) {
                    // 定义赋值新的token
                    localStorage.setItem("access-token", data.result["access_token"]);
                    localStorage.setItem("token-type", data.result["token_type"]);
                    localStorage.setItem("refresh-token", data.result["refresh_token"]);
                    // 已经刷新了token，将所有队列中的请求进行重试
                    onAccessTokenFetched();
                    // 再发请求 
                    return instance(error.response.config);
                } else {
                    console.log('请求refreshToken接口失败', error)
                    isRefreshing = true
                    return false;
                }
            }).catch(() => {
                console.log('请求refreshToken接口异常', error)
                return false;
            }).finally(() => {
                isRefreshing = true
            })
        } else {
            // 正在刷新token 将失败401的请求放入队列
            const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber(() => {
                    resolve(instance(error.response.config))
                })
            });
            return retryOriginalRequest;
        }
    }
    // return Promise.reject(error)
})

// 获取token请求
function getToken(data) {
    return axios.post(`${baseUrl}/open-api/oauth/get-token`,data,{ headers: { 'Content-Type': "application/json" } });
}
// 刷新token请求
function refreshTokenFn(data) {
    return axios.post(`${baseUrl}/open-api/oauth/refresh-token`, data, { headers: { 'Content-Type': "application/json" } });
}

export default instance