<template>
  <div class="top-nav-mian" @click="hideMoreLogo">
    <div class="nav-box-rel">
      <div class="left-logo" style="margin-left: .12rem;">
        <div class="more-logo">
          <a @click="goIndex" class="img1">
            <img src="../assets/img/logo.png?jkdjkd" alt="">
          </a>
          <!--					<img class="img2" src="../assets/img/xiala.png" alt="">-->
        </div>
        <!--				<div class="logo-more" v-show="logoMore">-->
        <!--					<div class="logo-more-rel">-->
        <!--						<img class="img1" src="../assets/img/xiala-more.png" alt="">-->
        <!--						<a href="https://m.zui.com/overview14" class="zui13">-->
        <!--							<img src="../assets/img/zui13-logo.png" alt="">-->
        <!--						</a>-->
        <!--					</div>-->
        <!--				</div>-->
      </div>
      <div class="right-list">
        <a @click="goIndex" class="li lantingheijian">首页</a>
        <!--				<div target="_blank"  class="li lantingheijian">-->
        <!--          <a target="_blank" href="https://www.zui.com/static/zui2/#/newMessage">最新消息</a>-->
        <!--          </div>-->
        <!--				<div target="_blank"  class="li lantingheijian">-->
        <!--          <a target="_blank" href="https://www.zui.com/static/zui2/#/feedback">意见反馈</a>-->
        <!--        </div>-->
        <!--				<div target="_blank" class="li lantingheijian">-->
        <!--          <a target="_blank" href="https://www.zui.com/static/zui2/#/skillsMain">使用技巧</a>-->
        <!--        </div>-->
        <div  @click="goNewMessage" class="li lantingheijian">最新消息</div>
        <div  @click="goFeedback" class="li lantingheijian">意见反馈</div>
        <div  @click="goSkills" class="li lantingheijian">使用技巧</div>
        <!--				<a target="_blank" href="https://one.lenovo.com/index.html" class="li lantingheijian">超级互联</a>-->
        <a target="_blank" href="https://club.lenovo.com.cn/forum-1349-1.html" class="li lantingheijian">论坛</a>
        <a target="_blank" href="https://activity.lenovo.com.cn/xiaofei/pingban/xxpb.index.html"
           class="li lantingheijian">商城</a>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../utils/http";
// npm引入
import tezignSdk from '@tezignlab/tezign-external-track';
import clickPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/click';
import pageViewPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/page-view';
import interfaces from "../utils/baseUrl";

export default {
  beforeMount: function () {
    // this.getBaseInfo2()

    this.getBaseInfo2();

  },
  data() {
    return {
      logoMore: false
    }
  },
  methods: {
    //
    statisticsFn() {
      const token = localStorage.getItem("access-token");
      // 初始化
      var httpReturnData = tezignSdk.init({
        tenant_id: 't329',
        client_id: 'lenovo_zui',
        // tenant_id:"track2.0_test",
        // client_id:"track2.0_test",
        token,
        http: {
          api: 'https://open-auth.tezign.com/open-api/standard/v1/save-client-track-log',
          sendType: 'XHR',
        },
        sender: {
          enableBatchSend: false,
        },
      })
      // 注册插件
      tezignSdk.use([
        pageViewPlugin(),
        clickPlugin({
          /**无侵入点击事件的自定义属性*/
          clickEventAttr: 'data-track-click',
          /**无侵入点击事件的冒泡自定义属性*/
          clickEventBubble: 'data-track-bubble',
          clickEventCode: 'data-track-click-code'
        })
      ])
    },
    getBaseInfo2(data) {
      var $this = this;
      var data = {
        token: "",
        param: {
          blockId: interfaces.blockIdArr.skillBlockID,
        },
      };
      instance
          .post(`/open-api/standard/v1/getPublishSetting`, data, {
            headers: {
              "Content-Type": "application/json",
              "Token-type": "bearer",
            },
          })
          .then((res) => {

            if (res && res.data.result) {
              // 获取初始化数据
              $this.statisticsFn()
            } else {

              $this.statisticsFn()
            }

          });
    },
    goIndex() {
      this.$router.push({
        name: 'index',
      })
    },
    goNewMessage() {
      this.$router.push({
        name: 'newMessage',
      })
    },
    goFeedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
    goSkills() {
      this.$router.push({
        name: 'skillsMain',
      })
    },
    showMoreLogo() {
      this.logoMore = true
    },
    hideMoreLogo() {
      this.logoMore = false
    }
  }
};
</script>
<style scoped>
.top-nav-mian {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: .96rem;
  line-height: .96rem;
  //background: linear-gradient(to right, #d331f5  , #4394ff);
  background: #5C4B7F;
  /* overflow: hidden; */
  z-index: 999999;
}

.top-nav-mian .nav-box-rel {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  justify-content: center;
}

.top-nav-mian .nav-box-rel .left-logo {
  width: 0.98rem;
  height: 0.35rem;
  margin-left: 0.52rem;
  margin-top: 0.27rem;
  float: left;
}

.top-nav-mian .nav-box-rel .left-logo .logo-more {
  position: absolute;
  top: .4rem;
  left: .01rem;
  width: 2rem;
  height: 1.25rem;
  /* display: none; */
}

/* .top-nav-mian .nav-box-rel .left-logo:hover .logo-more{
	display: block;
} */
.top-nav-mian .nav-box-rel .left-logo .logo-more .logo-more-rel {
  position: relative;
  width: 100%;
  height: 100%;
}

.top-nav-mian .nav-box-rel .left-logo .logo-more .logo-more-rel .zui13 {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: .8rem;
}

.top-nav-mian .nav-box-rel .left-logo .logo-more .logo-more-rel .zui13 img {
  display: block;
  width: 100%;
}

.top-nav-mian .nav-box-rel .left-logo .logo-more .logo-more-rel .img1 {
  width: 100%;
  height: 1.25rem;
}

.top-nav-mian .nav-box-rel .left-logo .more-logo {
  display: flex;
  align-items: center;
}

.top-nav-mian .nav-box-rel .left-logo .img1 {
  display: block;
  width: .99rem;
  /* height: ; */
}

.top-nav-mian .nav-box-rel .left-logo .img1 img {
  display: block;
  width: 100%;
}

.top-nav-mian .nav-box-rel .left-logo .img2 {
  display: block;
  width: .16rem;
  height: .11rem;
  margin-top: .12rem;
  margin-left: .12rem;
}

.top-nav-mian .nav-box-rel .right-list {
  float: left;
  overflow: hidden;
  margin-left: .3rem;
}

.top-nav-mian .nav-box-rel .right-list .li {
  display: block;
  padding: 0 .1rem;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  font-size: .24rem;
  float: left;
}
</style>
