import axios from 'axios'
import md5 from 'js-md5' 

var appKey 
var appSecret
var salt
var blockIdArr
var host = window.location.hostname;
//请求接口地址
var baseUrl;
if (host == 'zui.com') {
    baseUrl="https://vms-exapi.tezign.com"
    appKey="6m8u6euydhd6xggueef5xb2wdsgd9xoz"
    appSecret="yqzc8186rci6hii5xcnm5a9c8i2hu9j1"
    salt="cxgnyu0l"
    blockIdArr={
        /*
        374 最新消息Banner
        327 最新消息>内容详情
        362 更新信息>机型图
        334 更新记录>内容详情
        375 内测机型Banner
        366 内侧机型>机型列表
        363 二维码-微信
        364 二维码-微博
        376 意见反馈-Banner
        368 意见反馈-文章 （热点问题）
        */
       indexBanner:375,
       newMsg:327,
       newsjixing:362,
       update:334,
       neicebanner:376,
       neicejixing:366,
       vxcode:363,
       wbcode:364,
       opinionBanner:374,
       opinionarticle:368,

       //使用技巧
       skillBlockID:485,
    
    }
}else{
    // baseUrl="https://vms-test-env1-exapi.tezign.com"  
    // appKey = "16cjm234de0gye1dtlrv60clexna544w"
    // appSecret="mdqqxeu62mqmpvhg1udgrmr3eslit4rf"
    // salt="0hm1d6dx";
    baseUrl="https://vms-exapi.tezign.com"
    appKey="6m8u6euydhd6xggueef5xb2wdsgd9xoz"
    appSecret="yqzc8186rci6hii5xcnm5a9c8i2hu9j1"
    salt="cxgnyu0l"
    blockIdArr={
         /*
        374 最新消息Banner
        327 最新消息>内容详情
        362 更新信息>机型图
        334 更新记录>内容详情
        375 内测机型Banner
        366 内侧机型>机型列表
        363 二维码-微信
        364 二维码-微博
        376 意见反馈-Banner
        368 意见反馈-文章 （热点问题）
        */
       indexBanner:375,
       newMsg:327,
       newsjixing:362,
       update:334,
       neicebanner:376,
       neicejixing:366,
       vxcode:363,
       wbcode:364,
       opinionBanner:374,
       opinionarticle:368,
       //使用技巧
       skillBlockID:485,
    
    }
}
/**
 * 关于 nonce 的生成
 * 可以是'随机字符串'
 */
function createNonce(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

// 需要安装 js-md5 或者 crypto-js
// pnpm install js-md5 


/**
 * 生成签名
 * 推荐 appKey，appSecret 通过全局的方式注入
 */

// 生成时间戳 
const timestamp = new Date().getTime();
// 生成随机字符串
const nonce = createNonce();

const sign = md5(salt + md5(appKey + timestamp + nonce + appSecret));


function headers(){ 
    // 生成时间戳 
    var timestamp = new Date().getTime();
    // 生成随机字符串
    var nonce = createNonce();

    var sign = md5(salt + md5(appKey + timestamp + nonce + appSecret));
    return{
        'X-App-Key': appKey, 
        'S-Sign': sign, 
        'S-Timestamp': timestamp, 
        'S-Nonce': nonce
    }
}
function postHeaders(){
     // 生成时间戳 
     var timestamp = new Date().getTime();
     // 生成随机字符串
     var nonce = createNonce();
 
     var sign = md5(salt + md5(appKey + timestamp + nonce + appSecret));
     return {
        'X-App-Key': appKey, 
        'S-Sign': sign, 
        'S-Timestamp': timestamp, 
        'S-Nonce': nonce,
        'Content-Type': 'application/json'
     } 
}

const interfaces = {
	//内容分发看板
    headers,
    postHeaders,
    baseUrl,
    blockIdArr
}

export default interfaces;